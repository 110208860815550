<template>
  <v-col cols="12" md="12">
    <h3>App Branding</h3>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Element</th>
            <th class="text-left">Colors</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Menu Background</td>
            <td>
              <color-picker-dialog
                v-model="brandingData.menuBackgroundColor"
                @input="save()"
              />
            </td>
          </tr>
          <tr>
            <td>Menu Text Color</td>
            <td>
              <color-picker-dialog
                v-model="brandingData.menuTextColor"
                @input="save()"
              />
            </td>
          </tr>
          <tr>
            <td>Content text color</td>
            <td>
              <color-picker-dialog
                v-model="brandingData.contentColor"
                @input="save()"
              />
            </td>
          </tr>
          <tr>
            <td>Font Family</td>
            <td>
              <v-select
                :items="fonts"
                item-text="displayName"
                item-value="fontName"
                v-model="brandingData.fontFamily"
                @change="save()"
              >
                <template slot="selection" slot-scope="data">
                  <!-- HTML that describe how select should render selected items -->
                  {{ data.item.displayName }}
                </template>
                <template slot="item" slot-scope="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  <span v-bind:style="'font-family:' + data.item.fontName">
                    {{ data.item.displayName }}
                  </span>
                </template>
              </v-select>
            </td>
          </tr>
          <tr>
            <td>Font Size</td>
            <td>
              <v-select
                :items="fontSizes"
                item-text="sizeName"
                item-value="fontSize"
                v-model="brandingData.fontSize"
                @change="save()"
              >
              </v-select>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <h3 class="mt-4">Coach Logo</h3>
    <h6>Click on the image to upload a logo</h6>
    <v-sheet>
      <v-col class="d-flex child-flex vimg-container" cols="2">
        <v-img
          :src="currentCoach.coachLogo"
          class="grey lighten-2"
          @click="chooseLogo()"
        >
        </v-img>
      </v-col>
      <div>
        <input
          class="coachLogoUpload"
          truncate-length="15"
          type="file"
          accept="image/png, image/jpg, image/bmp"
          style="display: none"
          @change="updateCoachLogo"
        />
      </div>
    </v-sheet>
  </v-col>
</template>
<script>
import ColorPickerDialog from "../../components/ColorPickerDialog.vue";
import gql from "graphql-tag";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AppBranding",
  props: ["currentCoach", "brandingData"],
  data() {
    return {
      fontSize: "",
      fontFamily: "",
    };
  },
  components: {
    ColorPickerDialog,
  },
  methods: {
    ...mapActions(["axUpdateCoachLogo", "addCoachLink", "deleteCoachLink"]),
    chooseLogo() {
      document.querySelector("input.coachLogoUpload").click();
    },
    save() {
      const id = parseFloat(this.currentCoach.id);
      this.$apollo.mutate({
        mutation: gql`
          mutation updateCoachBranding(
            $id: Float!
            $data: CoachBrandingInput!
          ) {
            updateCoachBranding(id: $id, data: $data) {
              id
            }
          }
        `,
        variables: {
          id,
          data: this.brandingData,
        },
      });
    },
    updateCoachLogo(e) {
      this.onFilePicked(e);
    },
    onFilePicked(e) {
      const files = e instanceof File ? e : e.srcElement.files[0];
      const coachId = this.currentCoach.id;
      const uploadCoachLogo = this.axUpdateCoachLogo;

      const fr = new FileReader();
      fr.readAsDataURL(files);
      fr.addEventListener("load", () => {
        this.headShotBase64 = fr.result;

        uploadCoachLogo({
          coachId,
          base64Logo: fr.result,
        });
      });
    },
  },
  computed: {
    disableAddLink() {
      return (
        this.currentCoach.coachLinks && this.currentCoach.coachLinks.length == 6
      );
    },
    showCoachLinks() {
      return (
        this.currentCoach.coachLinks && this.currentCoach.coachLinks.length > 0
      );
    },
    ...mapGetters({
      fontSizes: "fontSizes",
      fonts: "fonts",
    }),
  },
};
</script>

<style scoped>
@import url("./coachProfile.less");
</style>
