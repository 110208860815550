<template>
  <div class="container">
    <!-- <h3>Preview Coach</h3> -->
    <v-sheet
      rounded=""
      class="pa-0 preview"
      :style="
        `font-family:${brandingData.fontFamily};font-size:${brandingData.fontSize};`
      "
    >
      <div
        class="px-4 preview-title"
        :style="
          `background:${brandingData.menuBackgroundColor};color:${brandingData.menuTextColor}`
        "
      >
        <span class="preview-title">{{ contactInfoData.contactInfoHeader }}</span>
      </div>
      <div class="preview-body">
        <div class="preview-contact-photo">
          <img class="avatar" :src="contactInfoData.contactInfoPhoto" />
          <div :style="`color:${brandingData.contentColor}`">
            {{ contactInfoData.contactInfoName }}
          </div>
        </div>
        <div class="preview-content-div">
          <p
            class="preview-content"
            :style="`color:${brandingData.contentColor}`"
            v-html="brHtml"
          >
          
          </p>
          <br />
        </div>
        <div class="sites">
          <v-sheet class="px-1 mx-auto">
            <v-list-item
              v-for="(link, index) in currentCoach.coachLinks"
              :key="index"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <a
                    :style="`color:${brandingData.contentColor}`"
                    class="no-underline"
                    :href="link.url"
                    target="_blank"
                  >
                    <i
                      :style="`color:${brandingData.contentColor}`"
                      :class="`${getIconsForLinkTypes(link)} `"
                    ></i>
                    {{ link.label }}
                  </a>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-sheet>
        </div>
      </div>
      <div
        class="preview-footer"
        :style="
          `background:${brandingData.menuBackgroundColor};color:${brandingData.menuTextColor}`
        "
      >
        <div class="d-flex justify-space-around">
          <div v-for="(item, key) in tabItems" :key="key" class="pa-2">
            <i :class="`fa-light fa-${item.icon} fa-2x`"></i>
            <h5>{{ item.label }}</h5>
          </div>
        </div>
      </div>
    </v-sheet>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "Preview",
  props: ["contactInfoData", "brandingData", "currentCoach"],
  data() {
    const tabItems = [
      {
        label: "Home",
        icon: "house-blank",
        href: "/tabs/home",
      },
      {
        label: "More",
        icon: "paperclip",
      },
      {
        label:
          (this.contactInfoData && this.contactInfoData.nameInMainMenu) ||
          "Coach",
        icon: "user-large",
      },
      {
        label: "Decks",
        icon: "memo",
      },
      {
        label: "Settings",
        icon: "gear",
      },
    ];
    const faIconsForLinkTypes = [
      {
        linkName: "Web",
        iconName: "globe",
        faStyle: "fas",
      },
      {
        linkName: "Facebook",
        iconName: "facebook",
        faStyle: "fa-brands",
      },
      {
        linkName: "InstaGram",
        iconName: "instagram",
        faStyle: "fa-brands",
      },
      {
        linkName: "Twitter",
        iconName: "twitter",
        faStyle: "fa-brands",
      },
      {
        linkName: "LinkedIn",
        iconName: "linkedin",
        faStyle: "fa-brands",
      },
      {
        linkName: "Pinterest",
        iconName: "pinterest",
        faStyle: "fa-brands",
      },
      {
        linkName: "Vimeo",
        iconName: "vimeo",
        faStyle: "fa-brands",
      },
      {
        linkName: "Youtube",
        iconName: "youtube",
        faStyle: "fa-brands",
      },
      {
        linkName: "Social Media/Text",
        iconName: "comments",
        faStyle: "fas",
      },
      {
        linkName: "Video/Graphic",
        iconName: "photo-video",
        faStyle: "fas",
      },
      {
        linkName: "PDF Document",
        iconName: "file-pdf",
        faStyle: "fas",
      },
      {
        linkName: "Phone",
        iconName: "mobile",
        faStyle: "fas",
      },
    ];
    return {
      faIconsForLinkTypes,
      tabItems,
    };
  },
  methods: {
    getIconsForLinkTypes(needleLink) {
      let classString = "";

      const thisLink = this.faIconsForLinkTypes.find((link) => {
        return link.linkName === needleLink.linkType;
      });

      if (thisLink && thisLink.faStyle) {
        classString += thisLink.faStyle;
      } else {
        classString += `fa-solid`;
      }
      return thisLink
        ? `${classString} fa-${thisLink.iconName}`
        : `${classString} fa-browser`;
    },
    getLinkIcon(linkType = "Web") {
      return linkType ? `mdi-${linkType.toLowerCase()}` : "mdi-web";
    },
   
  },
  computed:{
    brHtml(){
      console.log(this.contactInfoData.contactInfoIntroduction, "CIiD")
      const val = this.contactInfoData  && this.contactInfoData.contactInfoIntroduction ?  this.contactInfoData.contactInfoIntroduction.replace(/(\r\n|\r|\n)/g,"<br>") : ''
      return val
    }
  }
});
</script>

<style scoped lang="less">
.preview {
  padding: 0 !important;
  height: 640px;
  overflow: hidden;
  margin-bottom: 0px;
}
.preview-title {
  font-size: 20px;
  padding: 10px 14px;

  margin: 0;
}
.preview-contact-photo {
  text-align: center;
  font-size: 0.9rem;
  .avatar {
    border-radius: 57px;
    max-height: 75px;
    height: 75px;
    width: 75px;
    object-fit: cover;
  }
}
.preview-content-div {
  // margin: 14px;
  // padding: 12px;
  // border-bottom: 1px solid #bbb;
  // height: 51%;
  // overflow-y: scroll;
}
.preview-content {
  margin: 0;
}
.sites {
  padding: 8px 14px;
  margin-bottom: 10px;
}
.column {
  padding: 5px 12px;
  font-size: 14px;
}
.preview-footer {
  position: fixed;
  bottom: 20px;
  width: 94%;
  box-shadow: 0px -1px 1px #ccc;
  font-size: 12px;
  text-align: center;
}

.no-underline {
  text-decoration: none;
}
.no-underline i {
  font-size: 16px;
  color: #9e2263;
}

@import url("./coachProfile.less");
</style>
