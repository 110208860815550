<template>
  <div>
    <v-sheet rounded="">
      <v-card class="mx-auto" tile>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Title</v-list-item-title>
            <v-list-item-subtitle>
              <v-text-field
                outlined
                name="input-7-4"
                v-model="contactInfoData.contactInfoHeader"
                @change="saveContactInfo()"
              ></v-text-field>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-flex
          xs12
          class="text-xs-center text-sm-center text-md-center text-lg-center"
        >
          <img
            :src="headShotBase64 || contactInfoData.contactInfoPhoto"
            height="150"
            v-if="headShotBase64 || contactInfoData.contactInfoPhoto"
          />
        </v-flex>
        <v-flex
          xs12
          class="text-xs-center text-sm-center text-md-center text-lg-center"
        >
          <v-btn @click="$refs.image.click()"> Upload Headshot</v-btn>
        </v-flex>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-subtitle>
              <input
                style="display: none"
                truncate-length="15"
                type="file"
                ref="image"
                accept="image/*"
                @change="onFilePicked"
              />
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Header</v-list-item-title>
            <v-list-item-subtitle>
              <v-text-field
                outlined
                name="input-7-4"
                v-model="contactInfoData.contactInfoName"
                @change="saveContactInfo()"
              ></v-text-field>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Introduction / Description</v-list-item-title>
            <v-list-item-subtitle>
              <v-textarea
                outlined
                name="input-7-4"
                v-model="contactInfoData.contactInfoIntroduction"
                @change="saveContactInfo()"
                value=""
              ></v-textarea>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Name in Main Menu</v-list-item-title>
            <v-list-item-subtitle>
              <v-text-field
                outlined
                name="input-7-4"
                placeholder="Coach"
                v-model="contactInfoData.nameInMainMenu"
                @change="saveContactInfo()"
              ></v-text-field>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <hr />

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Add a link</v-list-item-title>
            <v-list-item-subtitle>
              <v-text-field
                outlined
                name="input-link-label"
                label="Label"
                v-model="linkData.label"
                value=""
              ></v-text-field>
              <v-select
                outlined
                name="input-link-url"
                label="Link Icon"
                v-model="linkData.linkType"
                :items="linkTypes"
                item-text="iconName"
                item-value="linkName"
              >
                <template slot="selection" slot-scope="data">
                  <!-- HTML that describe how select should render selected items -->
                  <font-awesome-icon
                    :icon="[data.item.faStyle || 'fab', data.item.iconName]"
                  />
                  &nbsp; &nbsp;{{ data.item.linkName }}
                </template>
                <template slot="item" slot-scope="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  <font-awesome-icon
                    :icon="[data.item.faStyle || 'fab', data.item.iconName]"
                  />
                  &nbsp; &nbsp;{{ data.item.linkName }}
                </template>
              </v-select>
              <v-text-field
                outlined
                name="input-link-url"
                :label="linkLabel"
                v-model="linkData.url"
                value=""
              ></v-text-field>
              <v-btn :disabled="disableAddLink" @click="addNewCoachLink()" small
                >Add link</v-btn
              >
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-for="(link, index) in currentCoach.coachLinks"
          :key="index"
          one-line
        >
          <v-list-item-content>
            <div>
              <v-btn
                @click="removeCoachLink(link.id)"
                icon
                small
                class="delete-btn inline"
              >
                <v-icon>mdi-delete</v-icon> </v-btn
              >&nbsp;
              <v-list-item-title class="inline">
                <span v-if="link.linkType != 'Phone'">
                  <a class="no-underline" :href="link.url" target="_blank">
                    <font-awesome-icon :icon="getLinkIcon(link.linkType)" />
                    <!-- <v-icon>{{}}</v-icon> -->
                    {{ link.label }}
                  </a>
                </span>
                <span v-if="link.linkType == 'Phone'">
                  <a
                    class="no-underline"
                    :href="'tel:' + link.url"
                    target="_blank"
                  >
                    <font-awesome-icon :icon="getLinkIcon(link.linkType)" />
                    <!-- <v-icon>{{}}</v-icon> -->
                    {{ link.label }}
                  </a>
                </span>
              </v-list-item-title>
            </div>
          </v-list-item-content>
        </v-list-item>
        <div
          v-if="!showCoachLinks"
          class="well ma-5 pa-5 text-center text-blue-300"
        >
          No Links have been added
        </div>
      </v-card>
    </v-sheet>
  </div>
</template>
<script>
import gql from "graphql-tag";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ScreenContent",
  props: ["contactInfoData", "linkData"],
  data() {
    return {
      linkTypes: [
        {
          linkName: "Web",
          iconName: "globe",
          faStyle: "fas",
        },
        {
          linkName: "Facebook",
          iconName: "facebook",
        },
        {
          linkName: "InstaGram",
          iconName: "instagram",
        },
        {
          linkName: "Twitter",
          iconName: "twitter",
        },
        {
          linkName: "LinkedIn",
          iconName: "linkedin",
        },
        {
          linkName: "Pinterest",
          iconName: "pinterest",
        },
        {
          linkName: "Vimeo",
          iconName: "vimeo",
        },
        {
          linkName: "Youtube",
          iconName: "youtube",
        },
        {
          linkName: "Social Media/Text",
          iconName: "comments",
          faStyle: "fas",
        },
        {
          linkName: "Video/Graphic",
          iconName: "photo-video",
          faStyle: "fas",
        },
        {
          linkName: "PDF Document",
          iconName: "file-pdf",
          faStyle: "fas",
        },
        {
          linkName: "Phone",
          iconName: "mobile",
          faStyle: "fas",
        },
      ],
      fontSize: "",
      fontFamily: "",
      headShotBase64: "",
    };
  },

  methods: {
    ...mapActions([
      "uploadCoachHeadShot",
      "addCoachLink",
      "deleteCoachLink",
      "axCoachBranding",
      "getCoachData",
    ]),
    onFilePicked(e) {
      const files = e instanceof File ? e : e.srcElement.files[0];
      const coachId = this.currentCoach.id;
      const uploadCoachHeadShot = this.uploadCoachHeadShot;

      const fr = new FileReader();
      fr.readAsDataURL(files);
      fr.addEventListener("load", () => {
        this.headShotBase64 = fr.result;

        uploadCoachHeadShot({
          coachId,
          headShotBase64: fr.result,
        });
      });
    },
    clickHeadshotUpload() {
      this.image.click();
    },
    getLinkIcon(linkType = "Web") {
      let icon = this.linkTypes.find((icon) => icon.linkName === linkType);
      if (!icon) return ["fas", "globe"];
      let prefix = icon.faStyle ? icon.faStyle : "fab";
      icon = icon.iconName;
      return linkType ? [prefix, `${icon}`] : ["fas", "globe"];
      // return linkType ? `mdi-${linkType.toLowerCase()}` : "mdi-web";
    },

    async saveContactInfo() {
      console.log("saveContactInfo Called", this.contactInfoData);
      const id = parseFloat(this.currentCoach.id);
      await this.$apollo.mutate({
        mutation: gql`
          mutation updateCoachBrandingContactInfo(
            $id: Float!
            $data: CoachBrandingContactInput!
          ) {
            updateCoachBrandingContactInfo(id: $id, data: $data) {
              id
            }
          }
        `,
        variables: {
          id,
          data: {
            ...this.contactInfoData,
            id: undefined,
            __typename: undefined,
          },
        },
      });

      this.axCoachBranding({ id: this.currentCoach.id });
    },
    uploadContactPhoto(e) {
      this.onFilePicked(e);
    },
    async addNewCoachLink() {
      //check for http:// or https:// in linkData.url
      if (
        this.linkData.url.substring(0, 6) == "https:" ||
        this.linkData.url.substring(0, 5) == "http:"
      ) {
        console.log("ok");
      } else {
        this.linkData.url = "https://" + this.linkData.url;
      }

      //If Phone remove https://   yes! I know why did we add it in the first place.   Shhhhh
      if (this.linkData.linkType == "Phone")
        this.linkData.url = this.linkData.url.replace("https://", "");
      await this.addCoachLink({
        coachId: this.currentCoach.id,
        label: this.linkData.label,
        url: this.linkData.url,
        linkType: this.linkData.linkType,
      });
      console.log(this.linkData);
      this.linkData.label = "";
      this.linkData.url = "";
      await this.getCoachData();
    },
    async removeCoachLink(id) {
      await this.deleteCoachLink({ id });
      await this.getCoachData();
    },
  },
  computed: {
    linkLabel() {
      return this.linkData.linkType == "Phone" ? "Phone (xxx-xxx-xxxx)" : "URL";
    },
    disableAddLink() {
      return (
        this.currentCoach.coachLinks && this.currentCoach.coachLinks.length == 6
      );
    },

    showCoachLinks() {
      return (
        this.currentCoach.coachLinks && this.currentCoach.coachLinks.length > 0
      );
    },
    ...mapGetters({
      currentCoach: "loggedInCoach",
      fontSizes: "fontSizes",
      fonts: "fonts",
    }),
  },
};
</script>

<style scoped>
.delete-btn i.mdi-delete {
  font-size: 15px;
  color: grey;
}
.no-underline {
  text-decoration: none;
}
.no-underline i {
  font-size: 16px;
  color: #9e2263;
}
.inline {
  display: inline;
}
.delete-btn {
  display: inline;
}
@import url("./coachProfile.less");
</style>
