<template>
  <v-container id="user-profile-view" fluid tag="section">
    <v-snackbar
      v-model="snackbarUpdate"
      timeout="1000"
      top
      color="primary"
      right
      text
    >
      Content Saved!
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbarUpdate = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-row>
      <v-col cols="12" md="12" style="display:none">
        <div class="actions-container">
          <div class="actions-groups">
            <div class="actions-group">
              <p><b>SAVE</b> CHANGES &</p>
              <div class="actions">
                <v-btn rounded @click="save()"> CONTINUE EDITING </v-btn>
                <!-- <v-btn rounded @click="save()"> RETURN TO DASHBOARD </v-btn> -->
              </div>
            </div>
            <div class="actions-group">
              <p><b>DISCARD</b> CHANGES &</p>
              <div class="actions">
                <v-btn rounded @click="confirmThenGoBack()">
                  RETURN TO DASHBOARD
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row justify="">
      <v-col cols="4" md="4">
        <Preview
          :currentCoach="currentCoach"
          :contactInfoData="contactInfoData"
          :brandingData="brandingData"
        />
      </v-col>
      <v-col cols="2" md="2">
        &nbsp;
      </v-col>
      <v-col cols="6" md="6">
        <v-tabs v-model="tab" align-with-title grow>
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab> BRANDING </v-tab>
          <v-tab> CONTENT </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <AppBranding
              :currentCoach="currentCoach"
              :brandingData="brandingData"
            />
          </v-tab-item>
          <v-tab-item>
            <ScreenContent
              :contactInfoData="contactInfoData"
              :linkData="linkData"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Preview from "../components/CoachProfile/Preview.vue";
import AppBranding from "../components/CoachProfile/AppBranding.vue";
// import WelcomePush from "../components/CoachProfile/WelcomePush.vue";
import gql from "graphql-tag";
import { mapActions, mapGetters } from "vuex";
import ScreenContent from "../components/CoachProfile/ScreenContent.vue";
export default {
  name: "UserProfileView",
  data() {
    return {
      snackbarUpdate: false,
      tab: null,
      linkData: { label: "", url: "" },
      menuBackground: "",
      menuTextColor: "",
      fontSize: "",
      fontFamily: "",
      contentColor: "",
      imageName: "",
      imageUrl: "",
      imageFile: "",
      brandingData: {},
      // contactInfoData: {},
    };
  },
  computed: {
    disableAddLink() {
      return (
        this.currentCoach.coachLinks && this.currentCoach.coachLinks.length == 6
      );
    },
    showCoachLinks() {
      return (
        this.currentCoach.coachLinks && this.currentCoach.coachLinks.length > 0
      );
    },
    ...mapGetters({
      contactInfoData: "getCoachBranding",
      currentCoach: "loggedInCoach",
    }),
  },
  components: {
    AppBranding,
    // WelcomePush,
    Preview,
    ScreenContent,
  },
  methods: {
    confirmThenGoBack() {
      if (confirm("Discard changes and leave this page? Are you sure ?")) {
        this.$router.push(`/app/`);
      }
    },
    getLinkIcon(linkType = "Web") {
      return `mdi-${linkType.toLowerCase()}`;
    },
    ...mapActions(["addCoachLink", "deleteCoachLink", "axCoachBranding"]),
    save() {
      const id = parseFloat(this.currentCoach.id);
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateCoachBranding(
              $id: Float!
              $data: CoachBrandingInput!
            ) {
              updateCoachBranding(id: $id, data: $data) {
                id
              }
            }
          `,
          variables: {
            id,
            data: this.brandingData,
          },
        })
        .then(() => {
          this.$router.push("/app");
        });
    },
    saveContactInfo() {
      console.log("saveContactInfo Called", this.contactInfoData);
      const id = parseFloat(this.currentCoach.id);
      this.$apollo.mutate({
        mutation: gql`
          mutation updateCoachBrandingContactInfo(
            $id: Float!
            $data: CoachBrandingContactInput!
          ) {
            updateCoachBrandingContactInfo(id: $id, data: $data) {
              id
            }
          }
        `,
        variables: {
          id,
          data: { ...this.contactInfoData, id: undefined },
        },
      });
    },
    getBranding() {
      const id = parseFloat(this.currentCoach.id);
      this.$apollo
        .query({
          query: gql`
            query CoachBranding($id: Float!) {
              CoachBranding(id: $id) {
                id
                menuBackgroundColor
                menuTextColor
                fontFamily
                fontSize
                contentColor
              }
            }
          `,
          fetchPolicy: "network-only",
          variables: {
            id,
          },
        })
        .then((resData) => {
          let data = resData.data.CoachBranding;
          delete data.id;
          delete data.__typename;
          this.brandingData = resData.data.CoachBranding;
        });
    },
    getContactInfo() {
      const id = parseFloat(this.currentCoach.id);

      this.axCoachBranding({ id });
      // .then((resData) => {
      //   let data = resData.data.CoachBranding;
      //   delete data.id;
      //   delete data.__typename;
      //   this.contactInfoData = resData.data.CoachBranding;
      // });
    },
    pickFile() {
      this.$refs.image.click();
    },
    uploadContactPhoto(e) {
      this.onFilePicked(e);
    },

    chooseLogo() {
      document.querySelector(".coachLogoUpload").click();
    },
    onFilePicked(e, targetAction = this.save) {
      const files = e instanceof File ? e : e.srcElement.files[0];

      const fr = new FileReader();
      fr.readAsDataURL(files);
      fr.addEventListener("load", () => {
        this.brandingData.contactInfoPhoto = fr.result;
        this.imageUrl = fr.result;
        // this.save();
        targetAction();
      });
    },
    async addNewCoachLink() {
      await this.addCoachLink({
        coachId: this.currentCoach.id,
        label: this.linkData.label,
        url: this.linkData.url,
      });
      this.linkData = {
        label: "",
        url: "",
      };
    },
    removeCoachLink(id) {
      this.deleteCoachLink({ id });
    },
  },
  mounted: function () {
    this.getBranding();
    this.getContactInfo();
  },
};
</script>

<style scoped>
.delete-btn i.mdi-delete {
  font-size: 15px;
  color: grey;
}
.no-underline {
  text-decoration: none;
}
.no-underline i {
  font-size: 16px;
  color: #9e2263;
}
.inline {
  display: inline;
}
.delete-btn {
  display: inline;
}
.preview {
  padding: 0 !important;
  height: 640px;
  overflow: hidden;
  margin-bottom: 20px;
}
.preview-title {
  font-size: 20px;
  padding: 10px 14px;
  box-shadow: 0 3px 3px #ccc;
  margin: 0;
}
.preview-content-div {
  /* margin: 14px;
  padding: 12px;
  border: 1px solid #bbb;
  height: 51%;
  overflow-y: scroll; */
}
.preview-content {
  margin: 0;
}
.sites {
  padding: 8px 14px;
  margin-bottom: 120px;
}
.column {
  padding: 5px 12px;
  font-size: 14px;
}
.preview-footer {
  box-shadow: 0px -1px 1px #ccc;
  font-size: 12px;
  text-align: center;
}

.actions-groups {
  display: flex;
}

.actions-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border: 2px solid #555;
  margin-left: 20px;
}

.actions-group button {
  margin: 0 4px;
  min-width: 240px !important;
}
.actions-container {
  display: flex;
  justify-content: flex-end;
}
</style>
