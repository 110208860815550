<template>
  <div>
    <v-row justify="start" align="center">
      <div class="inlineBox">
        <div class="colorBox" v-bind:style="setBackground(value)"></div>
      </div>
      <v-btn
        x-small
        class="float-right my"
        outlined
        color="primary"
        dark
        @click="dialog2 = true"
      >
        Select Color
      </v-btn>
      <v-btn
        x-small
        class="float-right"
        outlined
        color="primary"
        dark
        @click="dialog3 = true"
      >
        Add Color
      </v-btn>

      <v-dialog v-model="dialog2" max-width="500px">
        <v-card>
          <v-card-title> Select Color </v-card-title>
          <v-card-text>
            <div class="color-boxes">
              <div
                class="color-box small-box"
                @click="selectColor(color)"
                v-for="(color, index) in colorsSorted"
                :key="index"
                :style="`background-color: ${color}`"
              ></div>
            </div>
            <br />
            <div class="color-boxes">
              <div
                class="color-box small-box"
                @click="selectColor(color.hex)"
                v-for="color in coachColors"
                :key="color.id"
                :style="`background-color: ${color.hex}`"
              ></div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click="dialog2 = false"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog3" max-width="500px">
        <v-card>
          <v-form v-model="isFormValid">
            <v-card-title>
              <span>Add Color</span>
            </v-card-title>
            <v-card-text>
              <div class="inlineBox">
                <v-text-field
                  label="Enter hex code"
                  :rules="rules"
                  prefix="#"
                  v-model="customColor"
                  hide-details="auto"
                ></v-text-field>
              </div>
              <v-btn
                color="primary"
                dark
                :disabled="!isFormValid"
                @click="handleAddCustomColor(customColor)"
              >
                Add Color
              </v-btn>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="dialog3 = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { colors } from "../utils/colors";
import { mapActions, mapGetters } from "vuex";
import gql from "graphql-tag";
export default {
  props: ["value"],
  data() {
    return {
      customColor: "",
      colors: colors,
      isFormValid: false,
      dialog2: false,
      dialog3: false,
      notifications: false,
      sound: true,
      widgets: false,
      rules: [
        (value) => !!value || "Required.",
        (value) => (value && value.length == 6) || "Should be 6 characters",
        (value) => {
          const pattern = /^([0-9a-f]{3}){1,2}$/i;
          return pattern.test(value) || "Invalid code";
        },
      ],
    };
  },
  computed: {
    colorsSorted: () => {
      return colors.sort((a, b) => b > a);
    },
    ...mapGetters({
      currentCoach: "loggedInCoach",
      coachColors: "coachColors",
    }),
  },
  methods: {
    ...mapActions(["getCoachColors"]),
    setBackground(hex) {
      return { background: hex };
    },
    async selectColor(hex) {
      this.$emit("input", hex);
      this.dialog2 = false;
      this.dialog3 = false;
      this.customColor = "";
    },
    async handleAddCustomColor(hex) {
      const hexColor = `#${hex}`;
      this.$emit("input", hexColor);
      this.dialog2 = false;
      this.dialog3 = false;
      this.customColor = "";
      await this.$apollo.mutate({
        mutation: gql`
          mutation createCoachColor($data: CoachColorInput!) {
            createCoachColor(data: $data) {
              id
            }
          }
        `,
        variables: {
          data: {
            hex: hexColor,
            coachId: this.currentCoach.id,
          },
        },
      });
      this.getCoachColors({ coachId: this.currentCoach.id });
      console.log(this.coachColors);
    },
  },
  mounted() {
    this.getCoachColors({ coachId: this.currentCoach.id });
  },
};
</script>

<style scoped>
.colorBox {
  width: 30px;
  height: 30px;
  box-shadow: 0 1px 2px #000;
}
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #9e2263 !important;
}
.inlineBox {
  display: inline-block;
  margin-right: 10px;
}
button {
  margin-right: 10px;
}
</style>
